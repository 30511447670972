import VBreadcrumbs from './VBreadcrumbs'
import VBreadcrumbsItem from './VBreadcrumbsItem'

export { VBreadcrumbs, VBreadcrumbsItem }

export default {
  $_vuetify_subcomponents: {
    VBreadcrumbs,
    VBreadcrumbsItem
  }
}
